module.exports = {
    puerh : [{
        name : "团茶",
        url : "../../imgs/Brand/p1.png"
    },{
        name : "饼茶",
        url : "../../imgs/Brand/p2.png"
    },{
        name : "瓜茶",
        url : "../../imgs/Brand/p3.png"
    },{
        name : "紧茶",
        url : "../../imgs/Brand/p4.png"
    },{
        name : "砖茶",
        url : "../../imgs/Brand/p5.png"
    },{
        name : "沱茶",
        url : "../../imgs/Brand/p6.png"
    },{
        name : "竹筒茶",
        url : "../../imgs/Brand/p7.png"
    },{
        name : "散茶",
        url : "../../imgs/Brand/p8.png"
    },{
        name : "紧压茶",
        url : "../../imgs/Brand/p9.png"
    }],
    puer : [{
        name : "散茶",
        url : "../../imgs/Brand/p11.png"
    },{
        name : "紧压茶",
        url : "../../imgs/Brand/p12.png"
    }]
}