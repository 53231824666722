<template>
    <div class="index">
        <z-header :curActive="1"></z-header>
        <div class="typeArea">
            <z-title :textEn="`TRADITIONAL PU-ERH TEA (RAW TEA)`" :text="`传统普洱茶（生茶）`" :color="`#867d70`"></z-title>
            <div class="shop-list">
                <div v-for="(item,index) of teaData.puerh" :key="index" class="shop-item">
                    <img :src="require(`../../imgs/Brand/p`+(index+1)+'.png')">
                    <div>
                        <span>【</span>
                        <p>{{item.name}}</p>
                        <span>】</span>
                    </div>
                </div>
                <div class="shop-item" style="opacity: 0;height: 0;"></div>
                <span v-if="!screenState" style="width: 375px;" ></span>
            </div>
            <z-title :textEn="`MODERN PU'ER TEA`" :text="`现代普洱茶`" :color="`#867d70`"></z-title>
            <div class="shop-list">
                <div v-for="(item,index) of teaData.puer" :key="index" class="shop-item">
                    <img :src="require(`../../imgs/Brand/p`+(index+11)+`.png`)">
                    <div>
                        <span>【</span>
                        <p>{{item.name}}</p>
                        <span>】</span>
                    </div>
                </div>
                <span style="width: 375px;"></span>
            </div>
        </div>
	</div>
</template>
<script>
import zHeader from "../../components/header.vue"
import zTitle from "../../components/z-title.vue"
import teaData from "./data"
export default {
    components:{zHeader,zTitle},
    data(){
        return{
            teaData : {},
            screenState:false
        }
    },
    mounted(){
		// 初始化获取浏览器窗口大小
		let screenWidth = document.body.clientWidth;
		// 根据浏览器窗口大小判断是否显示页面内的部分内容
		if(screenWidth<1200){
			this.screenState=false;
		}else{
			this.screenState=true;
		}
	},
    created(){
        let that = this
        this.teaData = teaData;
        if(this.$route.query.id){
            if(this.$route.query.id == 1){
                if(that.screenState){
                    document.body.scrollTop = 650;
                    document.documentElement.scrollTop = 650;
                }else{
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                }
            }else if(this.$route.query.id == 2){
                document.body.scrollTop = 2300;
                document.documentElement.scrollTop = 2300;
            }
        }
    }
}
</script>
<style scoped>
    .index{
        background-color: #ebebe1;
        padding-bottom:150px;
    }
    .index .typeArea{
        display: flex;
        flex-direction: column;
    }
    .shop-list{
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .shop-item{
        width: 375px;
        height: 450px;
        background-color: #ffffff;
        border-radius: 4px;
        position: relative;
        border-top: 5px solid #d6ab7e;
        margin-top: 30px;
        cursor: pointer;
    }
    .shop-item:hover{
        box-shadow: 0px 0px 8px 0px 
            rgba(0, 0, 0, 0.2);
        border-top: 5px solid #867d70;
    }
    .shop-item:hover div>span{
        color: #845a3c;
    }
    .shop-item img{
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .shop-item div{
        display: flex;
        flex-direction: row;
        position: absolute;
        writing-mode: tb-rl;
        top: 30px;
        left: 35px;
        font-size: 24px;
	    font-weight: bold;
        color: #845a3c;
    }
    .shop-item div span{
        color: #d6ab7e;
    }
    @media only screen and (max-width:1199px) {
        .index{
            padding-bottom: 35px;
        }
        .typeArea{
            width: 100%;
        }
        .shop-list{
            margin: 0;
            padding: 0 20px;
            box-sizing: border-box;
        }
        .shop-item{
            margin: 0 5px 15px;
            width: 150px;
            height: 210px;
        }
        .shop-item div{
            top: 12px;
            left: 15px;
            font-size: 12px;
        }
    }
</style>